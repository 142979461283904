import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
import Twenty from "react-twentytwenty";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu/radeon"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/radeon.png"}) { ...eyecatchImg },
    allRadeonJson {
      nodes {
        name
        passmark
        vmark
        fullHd
        qhd
        fourK
        tdp
        price
        cospa
        year
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Radeon比較表 | AMD GPUの性能を世代やクラスで比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Feb 08 2025 00:21:48 GMT+0900 (日本標準時)">更新日:2025年2月8日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Geforce性能比較表" mdxType="Image" />
    <p>{`本サイトでは50以上の定番及び最新のAMD Radeon GPUに対し、簡単に性能の比較検討が行えるよう、信頼性の高いベンチマークである`}<a parentName="p" {...{
        "href": "https://www.videocardbenchmark.net/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`PassMark`}</a>{`、`}<a parentName="p" {...{
        "href": "https://benchmarks.ul.com/compare/best-gpus",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`3DMark`}</a>{`や、実測値の性能計測に特化したサイトである`}<a parentName="p" {...{
        "href": "https://www.gpucheck.com",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`GPUCHECK`}</a>{`からFPS(フレームレート)の情報を収集し、一覧として表にまとめている。`}</p>
    <p>{`この性能比較表を参照することで、`}<strong parentName="p">{`どの程度3Dゲームを快適に遊ぶことができるか、どちらのグラフィック機能が優れているかが人目で分かる`}</strong>{`ようになる。`}</p>
    <p>{`同様に内蔵グラフィックスやNvidia Geforce GPUも含めた`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`や、`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`もあるので追加で調べたい場合は参考にして欲しい。`}</p>
    <h2 {...{
      "id": "Radeonレイディオンとは",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Radeon%E3%83%AC%E3%82%A4%E3%83%87%E3%82%A3%E3%82%AA%E3%83%B3%E3%81%A8%E3%81%AF",
        "aria-label": "Radeonレイディオンとは permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Radeon(レイディオン)とは`}</h2>
    <p>{`RadeonはAMDというアメリカの会社が開発しているゲーム用グラフィックコアチップである。AMDはPlayStation 5やXBox Xのチップも納品しておりゲームにおける開発力が高い。`}</p>
    <p>{`AMDとして最終製品の販売は行っておらず、GIGABYTE、MSI、ASUS、玄人志向など多岐にわたるメーカーがRadeonのコアチップを製品に組み込み販売している。`}<strong parentName="p"><em parentName="strong">{`元のチップは同じであるため、描画性能に大差はない`}</em></strong>{`。これはNvidiaのGeforceも同様である。`}</p>
    <p>{`Radeonの主なシリーズは以下の通り。基本的には２年に１回パワーアップしたシリーズが登場する流れとなっている。`}</p>
    <AccentBox title="Radeonのシリーズ" mdxType="AccentBox">
  <li><span className="bold">RX 7000シリーズ</span>・・・RDNA3というAI能力を強化しRT(レイトレーシング)エンジンを搭載したシリーズ</li>
  <li><span className="bold">RX 6000シリーズ</span>・・・PS5、XBOX Xと同様のRDNA2というアーキテクチャを採用したレイトレーシングにも対応したシリーズ</li>
  <li><span className="bold">RX 5000シリーズ</span>・・・AMDの7nmゲーム体験GPUに搭載されたアーキテクチャーRDNAを採用したシリーズ。VRゲームにも最適</li>
  <li><span className="bold">RX 500シリーズ</span>・・・GCN(Graphcs core next)というアーキテクチャを採用したシリーズ。Full,HDでゲームを遊ぶのに十分な性能</li>
  <li><span className="bold">Mシリーズ</span>・・・Radeon 890Mなど。CPUの内蔵グラフィックとしてしばしば搭載される</li>
    </AccentBox>
    <h2 {...{
      "id": "Radeon-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Radeon-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Radeon 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Radeon 性能比較表`}</h2>
    <p>{`それでは下記に性能を示す。有名ベンチマークであるPassMark、3DMarkスコアと、ゲームを遊んだ時に期待できるFPSを各解像度ごとに併記している。表の見方や検索方法の例は下のメニューを開けて確認して欲しい。`}</p>
    <Meyasu mdxType="Meyasu" />
    <Accordion title="検索/フィルタリング方法の例・詳細" mdxType="Accordion">
      <p>ヘッダー部分をクリックすることでソートが行える。また、GPU名称はカンマ区切りをすることでor条件で調べられ、各スコアはレンジ(-)や不等号でフィルタリングすることができる。</p>
      <AccentBox title="フィルター方法の例(該当するフォームへ入力)" mdxType="AccentBox">
  <li><span className="bold">RX
    </span>・・・RXシリーズのGPUのみを表示</li>
  <li><span className="bold">5000 - 10000</span>・・・スコアが5000から10000まで、-の間にはスペースが必要</li>
  <li><span className="bold">{'<'}100</span>・・・TDPが100W未満の省電力グラフィックボードのみを表示</li>
  <li><span className="bold">{'>'}200</span>・・・参考価格が200ドルより上</li>
      </AccentBox>
    </Accordion>
    <p>{`また、やや主観も入るがPassMarkスコアの目安を下記に示しておく。`}</p>
    <AccentBox title="Passmarkの目安" mdxType="AccentBox">
  <li><span className="bold">25000~</span>・・・4K120FPSを目指したい時。レイトレーシングでもフレームレートを保ち続け最先端の映像体験を満喫。</li>
  <li><span className="bold">17000 - 25000</span>・・・4K60FPSやレイトレーシングの超美麗グラフィックや144Hzなど高FPSをコスパ良く体験。ゲーミングモニターが必須となる。</li>
  <li><span className="bold">11000 - 17000</span>・・・ほぼ全てのゲームをFullHD x 60FPSで遊ぶには十分な性能でVRにも対応。</li>
  <li><span className="bold">6000 - 11000</span>・・・ほぼ全てのゲームをFullHDで遊ぶことができるが画質調整は必要。VRにはやや力不足。</li>
  <li><span className="bold">3000 - 6000</span>・・・解像度やレンダリングのクオリティを十分に落とせば大体のゲームは遊べるが、ゲーミングPCは名乗れないレベル。</li>
  <li><span className="bold">0 - 3000</span>・・・あえて言おう、カスであると。マインクラフトやドラクエXなど軽いゲームなら遊べるものもあるが、多くのゲームは全くできないか最低画質でもカックカクになる。</li>
    </AccentBox>
    <HikakuTable {...props} cpuData={props.data.allRadeonJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      